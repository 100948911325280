<template>
  <responsive-layout
    v-if="$vuetify.breakpoint.mobile"
    poster="https://vod.eventcdn.net/pandora/smc2021/Profile_Bg%402x.png"
  >
    <v-sheet light class="transparent">
      <div class="main py-8">
        <v-row justify="center" align="center" class="mx-4 mb-8 mb-lg-12 pt-6">
          <v-col cols="12" style="max-width: 580px">
            <div class="mx-auto d-flex justify-center align-center">
              <h1 class="white--text my-profile-headline">MY PROFILE</h1>
              <v-icon dark left color="pink" class="ml-6 my-profile-icon">
                fas fa-user
              </v-icon>
            </div>
          </v-col>
        </v-row>

        <!-- Form -->
        <v-row
          align="center"
          justify="center"
          class="mx-4 my-profile-form-container"
        >
          <v-col cols="12" class="lighter-grey pa-0" style="max-width: 580px">
            <!-- Form -->
            <div class="px-4 py-6">
              <validation-observer ref="validator" #default="{ invalid }">
                <v-form @submit.prevent="onSubmit">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.name"
                            label="Name *"
                            placeholder="Enter your name"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="email address"
                          rules="required|email"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.email"
                            label="Email *"
                            placeholder="Enter your Email"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="store name"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.store_name"
                            label="Store Name *"
                            placeholder="Search for your Store Name"
                            persistent-placeholder
                            :items="formOptions.store_names"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="territory manager"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.territory_manager"
                            label="Territory Manager *"
                            placeholder="Search for your Territory Manager"
                            persistent-placeholder
                            :items="formOptions.territory_managers"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <!-- <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="registration type"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.registration_type"
                            label="Registration Type *"
                            placeholder="Search for your Registration Type"
                            persistent-placeholder
                            :items="formOptions.registration_types"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <p class="completed-profile-text">
                          A completed profile gives the best experience in the
                          Meet you Colleagues section.
                        </p>
                        <p class="completed-profile-text">
                          Complete your profile and receive 50 pts.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="location"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.location"
                            label="Location"
                            placeholder="Enter your location"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="about you"
                        >
                          <v-textarea
                            outlined
                            hide-details="auto"
                            v-model="form.about"
                            label="About you"
                            placeholder="Enter a Description"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-textarea>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="text-left mt-4">
                        <p class="completed-profile-text">
                          We hope you will take advantage of our new chat
                          feature this year! To network with other attendees who
                          share the same goals and interests as you, please
                          select your top interests for other audience members
                          to see.
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        class="
                          d-flex
                          flex-column
                          align-start
                          justify-start
                          pl-1
                        "
                      >
                        <span class="pl-2 interests-text-my-profile"
                          >Add Interests</span
                        >

                        <v-chip-group multiple v-model="form.interests">
                          <v-chip
                            v-for="(interest, idx) in availableInterests"
                            :key="idx"
                            active-class="black white--text"
                            :value="interest"
                            class="
                              white--text
                              rounded-0
                              elevation-0
                              font-weight-bold
                            "
                          >
                            {{ interest }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                      <v-col
                        cols="12"
                        class="
                          d-flex
                          flex-row flex-wrap
                          align-start
                          justify-start
                          pl-1
                        "
                      >
                        <span class="pl-2 interests-text-my-profile"
                          >Upload photo *</span
                        >
                      </v-col>
                      <v-col
                        cols="8"
                        lg="4"
                        class="
                          d-flex
                          flex-row flex-wrap
                          align-center
                          justify-center
                          ml-3
                          mb-4
                          file-upload-container
                        "
                      >
                        <v-file-input
                          truncate-length="500"
                          prepend-icon="fas fa-camera"
                          color="pink"
                          light
                          dense
                          flat
                          single-line
                          class="file-upload-my-profile"
                          v-model="form.profile_photo"
                          @change="Preview_image"
                          @click:clear="form.image_url = null"
                        ></v-file-input>
                        <v-img :src="form.image_url || form.photo"></v-img>
                      </v-col>
                    </v-row>
                    <!--<v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="change password"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.change_password"
                            label="Change Password"
                            placeholder="Enter a new password"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="retype new password"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.new_password"
                            label="Retype new Password"
                            placeholder="Retype your new password"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>-->

                    <v-row justify="start" class="px-3 mt-6">
                      <v-col
                        class="
                          d-flex
                          flex-column
                          justify-center
                          align-start
                          px-0
                        "
                      >
                        <v-btn
                          color="primary"
                          class="
                            black--text
                            font-weight-bold
                            rounded-0
                            elevation-0
                            update-profile-button
                          "
                          type="submit"
                          depressed
                          :loading="isLoading"
                          :disabled="isLoading || invalid"
                        >
                          Update profile
                        </v-btn>

                        <span class="mt-4" v-if="status">{{ status }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </validation-observer>
            </div>
            <!-- End form -->
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </responsive-layout>

  <aspect-ratio-layout
    v-else
    poster="https://vod.eventcdn.net/pandora/smc2021/Profile_Bg%402x.png"
  >
    <v-sheet light class="transparent" style="margin-bottom: 100px">
      <div class="main py-8">
        <v-row justify="center" align="center" class="mx-4 mb-lg-12 mt-5">
          <v-col cols="12" style="max-width: 580px">
            <div class="mx-auto d-flex justify-center align-center">
              <h1 class="white--text my-profile-headline">MY PROFILE</h1>
              <v-icon dark left color="pink" class="ml-6 my-profile-icon">
                fas fa-user
              </v-icon>
            </div>
          </v-col>
        </v-row>

        <!-- Form -->
        <v-row
          align="center"
          justify="center"
          class="mx-4 mb-12 my-profile-form-container"
        >
          <v-col
            cols="12"
            class="lighter-grey pa-0 mb-12"
            style="max-width: 580px"
          >
            <!-- Form -->
            <div class="px-4 py-6">
              <validation-observer ref="validator" #default="{ invalid }">
                <v-form @submit.prevent="onSubmit">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.name"
                            label="Name *"
                            placeholder="Enter your name"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="email address"
                          rules="required|email"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.email"
                            label="Email *"
                            placeholder="Enter your Email"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="store name"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.store_name"
                            label="Store Name *"
                            placeholder="Search for your Store Name"
                            persistent-placeholder
                            :items="formOptions.store_names"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="territory manager"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.territory_manager"
                            label="Territory Manager *"
                            placeholder="Search for your Territory Manager"
                            persistent-placeholder
                            :items="formOptions.territory_managers"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <!-- <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="registration type"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.registration_type"
                            label="Registration Type *"
                            placeholder="Search for your Registration Type"
                            persistent-placeholder
                            :items="formOptions.registration_types"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <p class="completed-profile-text">
                          A completed profile gives the best experience in the
                          Meet you Colleagues section.
                        </p>
                        <p class="completed-profile-text">
                          Complete your profile and receive 50 pts.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="location"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.location"
                            label="Location"
                            placeholder="Enter your location"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="about you"
                        >
                          <v-textarea
                            outlined
                            hide-details="auto"
                            v-model="form.about"
                            label="About you"
                            placeholder="Enter a Description"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-textarea>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" class="text-left mt-4">
                        <p class="completed-profile-text">
                          We hope you will take advantage of our new chat
                          feature this year! To network with other attendees who
                          share the same goals and interests as you, please
                          select your top interests for other audience members
                          to see.
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        class="
                          d-flex
                          flex-column
                          align-start
                          justify-start
                          pl-1
                        "
                      >
                        <span class="pl-2 interests-text-my-profile"
                          >Add Interests</span
                        >

                        <v-chip-group
                          column
                          multiple
                          v-model="form.interests"
                          class="pl-1"
                        >
                          <v-chip
                            v-for="(interest, idx) in availableInterests"
                            :key="idx"
                            active-class="black white--text"
                            :value="interest"
                            class="
                              white--text
                              rounded-0
                              elevation-0
                              font-weight-bold
                            "
                          >
                            {{ interest }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                      <v-col
                        cols="12"
                        class="
                          d-flex
                          flex-row flex-wrap
                          align-start
                          justify-start
                          pl-1
                        "
                      >
                        <span class="pl-2 interests-text-my-profile"
                          >Upload photo</span
                        >
                      </v-col>
                      <v-col
                        cols="8"
                        lg="4"
                        class="
                          d-flex
                          flex-row flex-wrap
                          align-center
                          justify-center
                          ml-3
                          mb-4
                          file-upload-container
                        "
                      >
                        <v-file-input
                          truncate-length="500"
                          prepend-icon="fas fa-camera"
                          color="pink"
                          light
                          dense
                          flat
                          single-line
                          class="file-upload-my-profile"
                          v-model="form.profile_photo"
                          @change="Preview_image"
                          @click:clear="form.image_url = null"
                        ></v-file-input>
                        <v-img :src="form.image_url || form.photo"></v-img>
                      </v-col>
                    </v-row>
                    <!--<v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="change password"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.change_password"
                            label="Change Password"
                            placeholder="Enter a new password"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="retype new password"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.new_password"
                            label="Retype new Password"
                            placeholder="Retype your new password"
                            persistent-placeholder
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>-->

                    <v-row justify="start" class="px-3 mt-6">
                      <v-col
                        class="
                          d-flex
                          flex-column
                          justify-center
                          align-start
                          px-0
                        "
                      >
                        <v-btn
                          color="pink"
                          class="
                            black--text
                            font-weight-bold
                            rounded-0
                            elevation-0
                            update-profile-button
                          "
                          type="submit"
                          depressed
                          :loading="isLoading"
                          :disabled="isLoading || invalid"
                        >
                          Update profile
                        </v-btn>

                        <span class="mt-4" v-if="status">{{ status }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </validation-observer>
            </div>
            <!-- End form -->
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </aspect-ratio-layout>
</template>

<script>
import axios from "axios";

// Data
import STORE_NAMES from "@/assets/data/storenames";
import TERRITORY_MANAGERS from "@/assets/data/territorymanagers";

// Components
import ResponsiveLayout from "@/layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "@/layouts/16by9Layout.vue";

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { mapGetters, mapState } from "vuex";
export default {
  name: "app-my-profile",

  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    const REGISTRATION_TYPES = [
      "Franchise Store Manager",
      "O&O Store Manager",
      "Franchise Owner",
      "District Manager",
      "Regional Sales Manager",
      "Franchise Key Decision Manager",
      "Multibrand Owner",
      "Baltimore HQ",
      "Columbia LC",
      "Vendor",
      "Territory Manager",
      "Key Account Manager",
      "N/A",
    ];
    return {
      isLoading: false,
      status: null,

      form: {
        name: null,
        email: null,
        store_name: null,
        territory_manager: null,
        // registration_type: null,
        livehouse_terms: null,
        location: null,
        // title: null,
        about: null,
        interests: [],
        profile_photo: null,
        change_password: null,
        new_password: null,
        image_url: null,
      },
      formOptions: {
        store_names: STORE_NAMES.map((store_name) => {
          return {
            text: store_name,
            value: store_name,
          };
        }),
        territory_managers: TERRITORY_MANAGERS.map((tm) => {
          return {
            text: tm,
            value: tm,
          };
        }),
        // registration_types: REGISTRATION_TYPES.map((registration_type) => {
        //   return {
        //     text: registration_type,
        //     value: registration_type,
        //   };
        // }),
      },
    };
  },

  mounted() {
    this.form = {
      ...this.form,
      ...this.me,
    };
  },

  computed: {
    ...mapState(["availableInterests"]),
    ...mapGetters(["me"]),
    isValid() {
      return Object.values(this.form).every((field) => !!field);
    },
  },

  methods: {
    Preview_image() {
      if (this.form.profile_photo) {
        this.form.image_url = URL.createObjectURL(this.form.profile_photo);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.status = null;
      console.log("Data:", this.form);

      const data = {
        name: this.form.name,
        store_name: this.form.store_name,
        location: this.form.location,
        about: this.form.about,
        territory_manager: this.form.territory_manager,
        interests: JSON.stringify(this.form.interests),
        profile_photo: this.form.profile_photo,
      };

      if (data.profile_photo) {
        // Convert image to base64
        const reader = new FileReader();

        reader.addEventListener(
          "load",
          async () => {
            data.photo = reader.result;

            console.log("Uploading profile data:", data);
            // Upload
            try {
              await this.$store.dispatch("updateMe", data);
              this.status = "Profile updated!";

              this.form = {
                ...this.form,
                ...this.me,
                // Reset photo fields
                profile_photo: null,
                image_url: null,
              };

              this.isLoading = false;
            } catch (e) {
              this.status =
                "There was an unexpected error while updating your profile. Please try again later.";
            }
          },
          false
        );
        reader.readAsDataURL(this.form.profile_photo);
      } else {
        try {
          await this.$store.dispatch("updateMe", data);
          this.status = "Profile updated!";

          this.form = {
            ...this.form,
            ...this.me,
            // Reset photo fields
            profile_photo: null,
            image_url: null,
          };

          this.isLoading = false;
        } catch (e) {
          this.status =
            "There was an unexpected error while updating your profile";
        }
      }

      // Track user
      if (Object.values(data).every((value) => !!value)) {
        await this.$tracking.trackPoint(19);
      }
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Profile_Bg%402x.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backface-visibility: hidden;
}*/
/*
Needs to add padding to inner field
.v-input:not(.v-input--checkbox) {
  border: none !important;
  padding: 10px !important;
} */
</style>
